import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import Fade from 'react-reveal/Fade';


const TagsContainer = styled.div`
  // margin: 1rem 0;
  // display: flex;
  // flex-direction: column;
  // //flex-wrap: unwrap;
  // justify-content: left;
  // a {
  //   //margin: 0 1rem 1rem 0;
  //   color: ${props => props.theme.colors.black.blue};
  //   padding-left: 10rem;
  //   background: ${props => props.theme.colors.white.grey};
  //   border-radius: 10px;
  //   &:hover {
  //     color: ${props => props.theme.colors.white.light};
  //     background: ${props => props.theme.colors.primary.light};
  //     border: ${props => props.theme.colors.primary.light};
  //   }
  // }
  @media all and (max-width: 775px) 
{

  //display:block;
  max-width: 100vw;
padding: 0%;

  //margin: 0 auto;
}
`;
const InnerContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding-top:3rem;
padding-bottom:3rem;
  justify-content: space-evenly;
  border-top: 0.01px solid white;
  //border-bottom: 0.01px solid white;
  margin-left: 5%;
  margin-right: 5%;  
  //flex-direction: row-reverse;
  background: #FFF4F2;

  @media all and (max-width: 775px) 
  {
    flex-direction: column !important;
    //align-self: center !important;
margin-left: 5%;
margin-right: 10%;
    // display:block;
    // //overflow: auto;
  }

`;
const OuterContainer = styled.div`

`;
const Info1 = styled.div`
//border-right: 1px solid white;

flex-direction: column !important;
    align-items: center;
    margin-top:1rem;
@media all and (max-width: 775px) 
{
  //display:block;

  //left: 50%;
  margin-top: 0px;
}
`;
const VideoDiv = styled.div`
max-width: 100vw;
padding: 1%;

@media all and (max-width: 775px) 
{

display:flex;



  //margin: 0 auto;
}
`;
const Loading = styled.div`
display: flex;
flex-wrap: nowrap;
height: 25rem;
`;
const Feature = styled.div`
display: flex;
flex-wrap: nowrap;
flex-direction: column !important;

`;
const FeatureHeader = styled.div`
display: flex;
flex-wrap: nowrap;
padding-bottom:1rem;
font-size:1.2rem;

`;

const FeaturesBlock = ({ list }) => (
  <TagsContainer>
    {list &&
      list.map(tag => {
        const upperTag1 = tag.featureHeader;
        const upperTag = tag.feature;
        const vidPath = tag.videoPath;     
        const vidPreview = tag.videoPreview;
        const y = tag.y;
        const x = tag.x;     
        return (
          //justifyContent:tag.justifyCont,
          <OuterContainer style= {{ background: '#'+tag.background}}>
          
          <InnerContainer style= {{ background: '#'+tag.background, flexDirection: tag.justifyCont, borderTopColor: '#'+ tag.borderCol}}>
          
          <Info1>
          <Fade big>
          <FeatureHeader style= {{ color: '#'+tag.featureHeadCol}}>{upperTag1}</FeatureHeader>
          
          <Feature style= {{ color: '#'+tag.featureCol}}>
          <a style = {{ paddingBottom:"1rem" }}>{upperTag}</a>
          {tag.features &&
      tag.features.map(des => {
        const desc = des.feature;
      
        return (

  <li>{desc}</li>
        );
      })}
          
          </Feature>          
          </Fade>
          </Info1>
          <Fade>

            <VideoDiv>                       
            <video poster={vidPreview} style = {{MozBorderRadius:"1rem",WebkitBorderRadius:"1rem",borderRadius:"1rem"}} width="100% !important" height="100% !important"  muted controls loop >
            {/* width="100% !important" height="100% !important" */}
                <source src= {vidPath} type="video/mp4" />
              
            </video>            
            </VideoDiv>
            </Fade>
          </InnerContainer>
          </OuterContainer>
        );
      })}
  </TagsContainer>
);

export default FeaturesBlock;

FeaturesBlock.propTypes = {
  list: PropTypes.array,
};