import React from 'react';
import Img from "gatsby-image"
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Layout } from 'layouts';
import FeaturesBlock from 'components/FeaturesBlock';
import Fade from 'react-reveal/Fade';
import AniLink from "gatsby-plugin-transition-link/AniLink";

import '../styles/prism';
//import NavBar from '../layouts/NavBar';

const MainInfo = styled.div`
   display: flex;
  justify-content:center;
  justify-self:center;
   position: relative;
//z-index:222;

  //min-height: 100%;
  // height: fit-content;
  // height: -webkit-fit-content;
  //background: #2b2523;  
  
`;
const MainImageWarper = styled.div`
   display: block;
  //justify-content:stretch;
   position: relative;
//z-index:222;
  height: 15rem;
 margin-top:4rem;
  // height: fit-content;
  // height: -webkit-fit-content;
  //background: #2b2523;  
  
  @media all and (max-width: 775px) {    
    margin-top: 3.5rem;
    height: 90%;
  }
`;

const Line = styled.div`

grid-area: b;
 //border-top: 1px solid white;
 display: grid;
 position: relative;  

 justify-self:center;
line-height:1.5rem;
 padding-left:20%;
 padding-right:20%;
 padding-top : 2%;
 font-size:1.1rem;
@media all and (max-width: 775px) {    
  padding-left: 8% ;
  padding-right:8%;
  padding-top : 5%;
  border-left: 0px solid white;
}
 color: #FFF4F2; 
 text-align: left;
`;
const Mlink = styled.div`
grid-area: a;
  display: grid;
  justify-content: center ;
  justify-self:center;
  font-size: 1rem !important;
  z-index:10;
  //position: relative;
  color: #FFF4F2; 
  //justify-self:stretch;
  //padding-top:1%;
  border-bottom: 2px solid #EA3C53;
  @media all and (max-width: 775px) {
    padding-top:5%;
    justify-content:stretch;
    //padding-right:35%;
    border-bottom: 0px solid #EA3C53;
    //justify-self:stretch;
        }
`;
const MTLWarper = styled.div`

position: relative;
display: grid;

padding-top:4rem;

z-index:222;
  grid-template-rows: 1fr ;
  //30% 10%;
  grid-template-columns: 1fr;
  justify-content:stretch;
  justify-self:center;
  grid-template-areas: 
  "a"
  "b"
  ;
  //grid-auto-flow: dense;
 
  @media all and (max-width: 775px) {
      grid-template-areas: 
      "a "      
      "b"
      
      ;

    grid-template-rows: 1fr ;
    grid-template-columns: 1fr;    
    padding-top:0rem;
    }
`;

const InnerGridMainLinks = styled.div`
position: relative;
//width: auto;
display: grid;

padding-left: 0%;
grid-template-rows: 1fr ;
grid-template-columns: repeat(4, 1fr);
justify-content:stretch;
justify-self:stretch;
  grid-template-areas: 
  "f  u  i  d"
  ;
  grid-gap: 3rem;
  //grid-auto-flow: dense;

  align-self: safe center ;

  @media all and (max-width: 775px) {
    padding-top: 5%;
    // grid-template-areas: 
    // "f u i d"
    // ;
    //padding: 2%;
    //justify-content:stretch;
    //justify-self:stretch;
    //grid-gap: 0px;
  //  grid-template-rows: 1fr;
//grid-template-columns: repeat(4, 1fr);
  }
`;
const Ahref = styled.something`
 
.something:after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #EA3C53;
  transition: width .3s;
}
.something:hover {
  text-decoration: none;
  color: #FFF;
}
.something:hover::after {
  width: 6.1rem;
  //transition: width .3s;
}

`;
const Ahref1 = styled.something1`
 
.something1 {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: #FFF4F2;
  transition: all 0.3s;
  &:hover {
    
  font-size: 1em;
  //font-weight: 600;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: #EA3C53;
  }
  .&:hover::after {
   // width: 6.1rem;
   transition: all 3.1s;
  }
}
`;
const Ahref2 = styled(AniLink)`
.something2 {
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
 background-color: #FFF4F2;
transition: all 0.3s;
&:hover {
  
font-size: 1em;
//font-weight: 600;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-color: #EA3C53;
}
}
`;
const GettingST = styled.div`

display:flex;
line-height: 85%;
position:relative;
flex-direction: column !important;
text-align: center;
padding-top:4rem;
//padding-bottom:2rem;
font-size: 4rem;
    font-weight: 400;

    @media all and (max-width: 775px) {
      font-size: 3rem;
    }
`;
const GettingStarted = styled.div`
position: relative;
//justify-content: stretch;
display: grid;
padding: 0 25rem;
overflow:hidden;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 
  "a b c"
  ;
  //padding: 0 10rem;
@media (max-width: 800px) {
  grid-template-areas: 
  "a" 
  "b"
  "c"
  ;
  padding: 0 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  }

`;

const DownLoad = styled.div`
grid-area: b;
justify-self: center;
//justify-content: flex-center;
flex-direction: column !important;
display:grid;
text-align:left;
padding: 5% 15%;

// &:hover {
//   //z-index: 102;
//   //box-shadow: ${props => props.theme.shadow.feature.small.hover};       
//   transform: scale(1.03);
// }

@media all and (max-width: 775px) {
  border-bottom: 1px solid #A9A9A9;
  justify-self: center;
  text-align:center;
//margin-left:5%;
//margin-right:5%;
  padding: 5% 0;

}
`;
const Docs = styled.div`
grid-area: a;
justify-self: center;
//justify-content: flex-center;
flex-direction: column !important;
display:grid;
text-align:left;
padding: 5% 15%;
@media all and (max-width: 775px) {
  border-bottom: 1px solid;
  justify-self: center;
  text-align:center;
  border-bottom-color: #B9A9A9;
  padding: 5% 0;
}
`;
const Resources = styled.div`
grid-area: c;
justify-self: center;
//justify-content: flex-start;
flex-direction: column !important;
display:grid;
text-align:left;
padding: 5% 15%;
// color:#EA3C53;
// font-size: 1.2rem;
// font-weight: 500;
@media all and (max-width: 775px) {
  //border-bottom: 1px solid black;
  justify-self: center;
  text-align:center;
  padding: 5% 0;
}
`;
const PostWrapper = styled.div`
overflow:hidden;

`;
const GHeader = styled.div`
color:#EA3C53;
font-size: 1.6rem;
font-weight: 500;
align-self:center;
padding:10% 0;
`;
const GDescription = styled.div`
color:#505050;
font-size: 1.3rem;
font-weight: 300;
align-self:left;
padding-bottom:10%;
`;
const GLinks = styled.div`
color:#505050;
font-size: 1.3em;
font-weight: 300;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-color: #EA3C53;
align-self:left;

 transition: all 0.4s;
&:hover {
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
 background-color: #a70000;
}

&:after {
  content: '';
  display: block;
  width: 0%;
  height: 3px;
  background: #a70000;
  transition: color .3s;
}
`;
const Fspace = styled.div`
display:flex;
padding:10%;
@media all and (max-width: 775px) {
  display:none;
}
`;
const ImgWrapper = styled.div`
display:grid;
align-self: center;
justify-self:center;
@media all and (max-width: 775px) {
  align-self: center;
  justify-self:center;
}
//padding-bottom:20%;
`;
const ImgWrapper1 = styled.div`
display:grid;
align-self: center;
justify-self:center;
@media all and (max-width: 775px) {
  align-self: center;
  justify-self:center;
}
`;
const ImgWrapper2 = styled.div`
display:grid;
align-self: center;
justify-self:center;
@media all and (max-width: 775px) {
  align-self: center;
  justify-self:center;
}
`;
const HighlightsList = styled.div`

position: relative;
//justify-content: center;
display: grid;
//top:-5rem;
overflow:hidden;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 
  "a b c d"
  ;
  //background: #2b2523;
  color: #FFF4F2;
  line-height: 2.5;
  //border: 1px solid #EA3C53;
  border-radius:0 0 10px 10px;
  font-size:1.1rem;
padding: 2rem 5rem;

@media (max-width: 800px) {

  grid-template-areas: 
  "a" 
  "b"
  "c"
  "d"
  ;
  line-height: 1.5;
  //margin:auto;
  padding: 2rem 2rem;
  grid-template-columns: 100%;
  grid-template-rows: 1fr ;
width:100%;
  }
  
`;
const Highlight = styled.div`
grid-area: a;
justify-self: stretch;
justify-content: flex-start;
flex-direction: column !important;
display:flex;
text-align:left;
padding: 0 15%;
line-height: 1.5;
@media all and (max-width: 775px) {
  //border-bottom: 1px solid;
  justify-self: stretch;
  //border-bottom-color: #B9A9A9;
  padding: 0 0 0 1rem;
  
}

`;
const Highlight1 = styled.div`
grid-area: b;
justify-self: stretch;
justify-content: flex-start;
flex-direction: column !important;
display:flex;
text-align:left;
line-height: 1.5;
padding: 0 15%;

@media all and (max-width: 775px) {
  //border-bottom: 1px solid;
  justify-self: stretch;
  //border-bottom-color: #B9A9A9;
  padding: 0 0 0 1rem;
}
`;
const Highlight2 = styled.div`
grid-area: c;
justify-self: stretch;
justify-content: flex-start;
flex-direction: column !important;
display:flex;
text-align:left;
padding: 0 15%;
line-height: 1.5;
@media all and (max-width: 775px) {
  //border-bottom: 1px solid;
  justify-self: stretch;
  //border-bottom-color: #B9A9A9;
  padding: 0 0 0 1rem;
}
`;
const Highlight3 = styled.div`
grid-area: d;
justify-self: stretch;
justify-content: flex-start;
flex-direction: column !important;
display:flex;
text-align:left;
padding: 0 15%;
line-height: 1.5;
@media all and (max-width: 775px) {
  //border-bottom: 1px solid;
  justify-self: stretch;
  //border-bottom-color: #B9A9A9;
  padding: 0 0 0 1rem;
}
`;
const Desc = styled.div`
justify-self: center;
justify-content: flex-start;
flex-direction: column !important;
display:list-item;
text-align:left;
padding: 3%;
border-radius: ${props => props.theme.borderRadius.default};
box-shadow: 3px 0px 2px rgba(0, 0, 1, 2.1);
font-size: 1.1rem;
list-style-type: circle;
@media all and (max-width: 800px) {
  //border-bottom: 1px solid;
  //justify-self: stretch;
  //border-bottom-color: #B9A9A9;
  //padding: 0 4;
  box-shadow: 1px 0px 2px rgba(0, 0, 0.2, 0.4);
}
`;
const FakeBlack = styled.div`
//background: #2b2523;  
position: absolute;
width:100%;
height:10rem;
`;
const Post = ({ data, pageContext }) => {
  const { next, prev } = pageContext;
  const post = data.markdownRemark;
  const mainCol = post.frontmatter.mainColor;

  const Fimage = post.frontmatter.youtube_link.childImageSharp.fluid;
  const Uimage = post.frontmatter.unitystore_link.childImageSharp.fluid;
  const Iimage = post.frontmatter.itch_link.childImageSharp.fluid;
  const Dimage = post.frontmatter.docs_link.childImageSharp.fluid;

  const mainimage = post.frontmatter.main_image.childImageSharp.fluid;

  return (

    <Layout>
      <PostWrapper>
<FakeBlack style = {{background : mainCol}}>
</FakeBlack>

      <MainImageWarper>
        
            <Img

              className="MIss"
              fluid={mainimage}
              imgStyle={{ objectFit: "contain" }}
              style=
              {{
                position: "relative",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100vw",
                height: "100%",
                background : mainCol
              }}
            />
</MainImageWarper>

        <MainInfo style = {{background : mainCol}}>
          
        <Fade cascade>
<MTLWarper style = {{background : mainCol}}>
            <Mlink style = {{background : mainCol}}>
              <InnerGridMainLinks style = {{background : mainCol}}>
             
                <Img
                  fluid={Fimage}
                  imgStyle={{ objectFit: "contain" }}
                  style={{ gridArea: "f", width: "2rem" }}
                />

                <Ahref1><a className="something1" href={post.frontmatter.mmmmLinks[1].link} style={{ gridArea: "f", color: "#EA3C53", position: "absolute", left: "0rem", top: "120%" }}><font color="#FFF4F2">forum</font></a></Ahref1>

                <Img
                
                  fluid={Dimage}
                  imgStyle={{ objectFit: "contain" }}
                  style={{ gridArea: "d", width: "2rem" }}
                />

                <Ahref2><AniLink fade className = "something2" to={post.frontmatter.mmmmLinks[3].link} style={{ gridArea: "d", color: "#EA3C53", position: "absolute", left: "0.2rem", top: "120%" }}> <font color="#FFF4F2">docs</font></AniLink></Ahref2>

                <Img
                  fluid={Uimage}
                  imgStyle={{ objectFit: "contain" }}
                  style={{ gridArea: "u", width: "2rem" }}
                />
                <Ahref1><a className="something1" href={post.frontmatter.mmmmLinks[0].link} style={{ gridArea: "u", color: "#EA3C53", position: "absolute", left: "0.2rem", top: "120%" }}><font color="#FFF4F2">store</font></a></Ahref1>

                <Img
                  fluid={Iimage}
                  imgStyle={{ objectFit: "contain" }}
                  style={{ gridArea: "i", width: "2rem" }}
                />
                <Ahref1> <a className="something1" href={post.frontmatter.mmmmLinks[2].link} style={{ gridArea: "i", color: "#EA3C53", position: "absolute", left: "0rem", top: "120%" }}><font color="#FFF4F2">itch.io</font></a> </Ahref1>

              </InnerGridMainLinks>
            </Mlink>
            {/* width: dynamicWidth, height: dynamicImageHeight  */}
            {/* //style={{ left: dynamicHeight, width: LinedynamicWidth, borderLeft: LineDynamicBorder, margin: lineMargin }} */}
            <Line style = {{background : mainCol}} >


              {data.markdownRemark.frontmatter.main_text}<br/><br/>
              {data.markdownRemark.frontmatter.main_text1}
              <br/><br/>
              <Ahref><a className="something" href="#GettingStarted" style={{ color: "#FFF4F2" }}><font color="#EA3C53"> Getting started</font></a></Ahref>


            </Line>
</MTLWarper>
</Fade>
        </MainInfo>
        
<HighlightsList style = {{background : mainCol}}>
<Fade cascade>
  <Highlight>
  {post.frontmatter.resourcesArr[0].resourceName}<br></br>
  {post.frontmatter.resourcesArr[0].resourceLink}
  {post.frontmatter.resourcesArr[0].resDescList &&
      post.frontmatter.resourcesArr[0].resDescList.map(des => {
        const desc = des.desc;
      
        return (
  <Desc>{desc}</Desc>
        );
      })}
  </Highlight>

  <Highlight1>
  {post.frontmatter.resourcesArr[1].resourceName}<br></br>
  {post.frontmatter.resourcesArr[1].resourceLink}
  {post.frontmatter.resourcesArr[1].resDescList &&
      post.frontmatter.resourcesArr[1].resDescList.map(des => {
        const desc = des.desc;
      
        return (
  <Desc>{desc}</Desc>
        );
      })}
  </Highlight1>

  <Highlight2>
  {post.frontmatter.resourcesArr[2].resourceName}<br></br>
  {post.frontmatter.resourcesArr[2].resourceLink}
  {post.frontmatter.resourcesArr[2].resDescList &&
      post.frontmatter.resourcesArr[2].resDescList.map(des => {
        const desc = des.desc;
      
        return (
  <Desc>{desc}</Desc>
        );
      })}
  </Highlight2>

  <Highlight3>
  {post.frontmatter.resourcesArr[3].resourceName}<br></br>
  {post.frontmatter.resourcesArr[3].resourceLink}

  {post.frontmatter.resourcesArr[3].resDescList &&
      post.frontmatter.resourcesArr[3].resDescList.map(des => {
        const desc = des.desc;
      
        return (
  <Desc>{desc}</Desc>
        );
      })}
  </Highlight3>

  {/* <Highlight4>
  {post.frontmatter.resourcesArr[4].resourceName}<br></br>
  {post.frontmatter.resourcesArr[4].resourceLink}

  {post.frontmatter.resourcesArr[4].resDescList &&
      post.frontmatter.resourcesArr[4].resDescList.map(des => {
        const desc = des.desc;      
        return (

  <li>{desc}</li>

        );
      })}
  </Highlight4> */}

</Fade>
   </HighlightsList>
        <FeaturesBlock list={post.frontmatter.features} />

        <GettingST id = "GettingStarted">
          <span>{post.frontmatter.getST}</span>
          <span style={{fontSize: "0.5em", fontWeight: "350", color: "#978e86" }}>{post.frontmatter.getSTSesc}</span>
        </GettingST>

        <GettingStarted>

          <DownLoad>
            <ImgWrapper1>
              <Img
                fluid={post.frontmatter.downImg.childImageSharp.fluid}
                imgStyle={{ objectFit: "contain" }}
                style={{ width: "5rem" , paddingBottom:"5rem"}}
              />
            </ImgWrapper1>

            {/* <GHeader>{post.frontmatter.download}</GHeader> */}
           <GDescription> Get latest version on your favorite store</GDescription>
            <GLinks><a href={post.frontmatter.mmmmLinks[0].link}>Unity Store</a></GLinks>
            <GLinks><a href={post.frontmatter.mmmmLinks[2].link} >Itch.io</a></GLinks>
            <Fspace/>
      </DownLoad>

      <Docs>

            <ImgWrapper>
              <Img
                fluid={post.frontmatter.DocsImg.childImageSharp.fluid}
                imgStyle={{ objectFit: "contain" }}
                style={{ width: "5rem", paddingBottom:"5rem" }}
              />
            </ImgWrapper>

            {/* <GHeader>{post.frontmatter.docs}</GHeader> */}
            <GDescription>Read usefull instructions to start using {post.frontmatter.title}.<br/> Get latest API and Documentation</GDescription>
            {/* {post.frontmatter.docsDesc} */}
            <GLinks><a href={post.frontmatter.mmmmLinks[3].link}>Documentation</a></GLinks>
            <Fspace/>      
      </Docs>

          <Resources>
            <ImgWrapper2>
              <Img
                fluid={post.frontmatter.ResImg.childImageSharp.fluid}
                imgStyle={{ objectFit: "contain" }}
                style={{ width: "5rem", paddingBottom:"5rem" }}
              />
            </ImgWrapper2>

            {/* <GHeader>{post.frontmatter.resourss}</GHeader> */}
            <GDescription>Watch extra screenshots, videos, live examples</GDescription>
            <GLinks><a href="https://www.youtube.com/channel/UCupf-2kducZfczqJgQbb1Dg/videos?view_as=subscriber">Videos</a></GLinks>
            <GLinks><a href={post.frontmatter.mmmmLinks[1].link}>Forum</a></GLinks>
            <Fspace/>
      </Resources>

        </GettingStarted>



        {/* <Header title={title} cover={image} />
      <Container>
        <Content input={html} />
        <FeaturesBlock list={post.frontmatter.tags || []} />
      </Container> */}
      </PostWrapper>
    </Layout>
  );
};

export default Post;

Post.propTypes = {
  pageContext: PropTypes.shape({
    prev: PropTypes.object,
    next: PropTypes.object,
  }).isRequired,
  data: PropTypes.object.isRequired,
};

const scrollTo = (id) => () => {
  const el = document.querySelector(id)
  if (el) return window.scrollTo(0, el.offsetTop - 20)
  return false
}

export const onRouteUpdate = ({ location: { hash } }) => {

  if (hash) {
    window.setTimeout(scrollTo(hash), 10)
  }
}

// ffmpeg -i 22.gif  -c:v libx264 -pix_fmt yuv420p -profile:v baseline -level 3.0 -crf 22 -preset veryslow -vf "scale=trunc(iw/2)*2:trunc(ih/2)*2" -an -movflags +faststart -threads 0 out1.mp4

export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      frontmatter {
        date
        title
        tags
        main_text
        main_text1
        mmmmLinks{
          id
          link
        }
        features{
          id
          justifyCont
          background
          features{
            id
            feature
          }
          featureHeader
          feature
          featureHeadCol
          featureCol
          borderCol
          x
          y
          videoPreview
          videoPath
        }
        ffddg
        getST
        getSTSesc
        download
        docs
        docsDesc
        resourss
        mainColor
        resourcesArr{
        id
        resourceName
        resourceLink
        haveList
        resDescList{
          id
          desc
        }
        }
        downImg{
          childImageSharp {            
            fluid(              
              quality: 100 
              maxWidth: 200                        
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }            
          }
        }
        ResImg {
          childImageSharp {            
            fluid(              
              quality: 100 
              maxWidth: 200                        
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }            
          }
        }
        DocsImg {
          childImageSharp {            
            fluid(              
              quality: 100 
              maxWidth: 200                        
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }            
          }
        }

        main_image {
          childImageSharp {
            
            fluid(              
              quality: 100 
              maxWidth: 500                        
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }            
          }
        }
        cover {
          childImageSharp {
            fluid(              
              quality: 100     
              maxWidth: 400         
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }            
          }
        }
        youtube_link {
          childImageSharp {
            fluid(              
              quality: 100     
              maxWidth: 100         
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }            
          }
        }
        itch_link {
          childImageSharp {
            fluid(              
              quality: 100 
              maxWidth: 100         
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }            
          }
        }
        unitystore_link {
          childImageSharp {
            fluid(              
              quality: 100     
              maxWidth: 100         
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }            
          }
        }
        docs_link {
          childImageSharp {
            fluid(              
              quality: 100     
              maxWidth: 200         
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }            
          }
        }

      }
    }
  }
`;
